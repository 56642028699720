export const t23= [
    {
      id: 0,
      name: "CHARAN REDDY",
      position: "PRESIDENT",
      alt: "CHARAN REDDY",
      image: "Team/t23/Charan-400-x-400-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 1,
      name: "HARSHITHA",
      position: "VICE PRESIDENT",
      alt: "HARSHITHA",
      image: "Team/t23/harshitha.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 2,
      name: "PRANAY",
      position: "TREASURER",
      alt: "PRANAY",
      image: "Team/t23/pranay.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 3,
      name: "SRI CHARAN",
      position: "EXTERNAL AFFAIRS",
      alt: "SRI CHARAN",
      image: "Team/t23/sri-chran.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 4,
      name: "ARJUN",
      position: "LEGALS LEAD",
      alt: "ARJUN",
      image: "Team/t23/arjun.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 5,
      name: "VAMSI KRISHNA",
      position: "PROMOTIONS LEAD",
      alt: "VAMSI KRISHNA",
      image: "Team/t23/e-vamsi-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 6,
      name: "DRUVASENA REDDY",
      position: "TECHNICAL LEAD",
      alt: "DRUVASENA REDDY",
      image: "Team/t23/druva.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 7,
      name: "LAKSHMI DEEPIKA",
      position: "TECHNICAL",
      alt: "LAKSHMI DEEPIKA",
      image: "Team/t23/deepika-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 8,
      name: "ABHISHEK JHA",
      position: "TECHNICAL",
      alt: "ABHISHEK JHA",
      image: "Team/t23/abhishek.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 9,
      name: "VIKAS",
      position: "CONTENT WRITER",
      alt: "VIKAS",
      image: "Team/t23/vikas.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 10,
      name: "JYOSHNA",
      position: "WEBSITE HANDLER",
      alt: "JYOSHNA",
      image: "Team/t23/jyoshna.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 11,
      name: "JAYASRI",
      position: "WEBSITE HANDLER",
      alt: "JAYASRI",
      image: "Team/t23/AKKENA-JAYASRI.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 12,
      name: "LAKSHMI PRASANNA",
      position: "LEGALS",
      alt: "LAKSHMI PRASANNA",
      image: "Team/t23/prasanna.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 13,
      name: "ARUNA SREE",
      position: "LEGALS",
      alt: "ARUNA SREE",
      image: "Team/t23/arunasree-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 14,
      name: "PRANATHI",
      position: "HOSPITALITY LEAD",
      alt: "PRANATHI",
      image: "Team/t23/pranthi.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 15,
      name: "NIKITHA",
      position: "HOSPITALITY",
      alt: "NIKITHA",
      image: "Team/t23/nikitha.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 16,
      name: "RITVIK",
      position: "SOCIAL MEDIA HANDLER",
      alt: "RITVIK",
      image: "Team/t23/rithivik.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 17,
      name: "AMRUT VARSH",
      position: "MEDIA",
      alt: "AMRUT VARSH",
      image: "Team/t23/amrut.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
  ];
  
  
  