export const t22 = [
    {
      id: 0,
      name: "SRIKANTH",
      position: "PRESIDENT",
      alt: "SRIKANTH",
      image: "Team/t22/C-SRIKANTH-2.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 1,
      name: "SUDHIKSHA",
      position: "VICE PRESIDENT",
      alt: "SUDHIKSHA",
      image: "Team/t22/Malla-Sai-Sudhiksha-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 2,
      name: "VAMSI KRISHNA",
      position: "TREASURER",
      alt: "VAMSI KRISHNA",
      image: "Team/t22/Vamsi-Krishna_.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 3,
      name: "BHARGAV SAI",
      position: "EXTERNAL AFFAIRS",
      alt: "BHARGAV SAI",
      image: "Team/t22/CH-BHARGAV-SAI-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 4,
      name: "SAI VISHNU",
      position: "PROMOTIONS",
      alt: "SAI VISHNU",
      image: "Team/t22/Sai-Vishnu-Gopisetti-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 5,
      name: "HARSHINI",
      position: "PROMOTIONS",
      alt: "HARSHINI",
      image: "Team/t22/Harshini-Kanamathareddy-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 6,
      name: "NAVEEN",
      position: "TECHNICAL LEAD",
      alt: "NAVEEN",
      image: "Team/t22/T-NAVEEN.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 7,
      name: "ARUN",
      position: "TECHNICAL",
      alt: "ARUN",
      image: "Team/t22/ArunKumarReddy.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 8,
      name: "HEMANTH",
      position: "TECHNICAL",
      alt: "HEMANTH",
      image: "Team/t22/Hemanth-Reddy-Sambavaram-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 9,
      name: "NAVYA",
      position: "TECHNICAL",
      alt: "NAVYA",
      image: "Team/t22/Navya-Sree-Konakanchi.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 10,
      name: "KRUTHI",
      position: "LEGALS",
      alt: "KRUTHI",
      image: "Team/t22/Kruthi-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 11,
      name: "SAI KIRAN",
      position: "HOSPITALITY LEAD",
      alt: "SAI KIRAN",
      image: "Team/t22/M-SAI-KIRAN-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 12,
      name: "ANUSHA",
      position: "HOSPITALITY",
      alt: "ANUSHA",
      image: "Team/t22/Anusha-Chinthala-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 13,
      name: "SAI SRI",
      position: "HOSPITALITY",
      alt: "SAI SRI",
      image: "Team/t22/SAI-SRI-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 14,
      name: "POOJITHA",
      position: "HOSPITALITY",
      alt: "POOJITHA",
      image: "Team/t22/Gadhiraju-Poojitha-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 15,
      name: "JAANAKI RAMA KRISHNA",
      position: "HOSPITALITY",
      alt: "JAANAKI RAMA KRISHNA",
      image: "Team/t22/Jaanaki-Rama-Krishna-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
    {
      id: 16,
      name: "HYUDAYNATH",
      position: "MEDIA",
      alt: "HYUDAYNATH",
      image: "Team/t22/Y-HRUDAYNATH-1.webp",
      socials: {
        linkedin: "/",
        github: "/",
        instagram:"/",
      },
    },
  ];
  
  
  